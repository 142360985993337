import { createSlice } from "@reduxjs/toolkit";
// utils
import axios from "../../utils/axios";
import { fDateOnly } from "../../utils/formatTime";
//
import { dispatch } from "../store";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  lotgagnants: {
    count: 0,
    data: [],
    num_pages: 1,
    page: 1,
    page_size: "5",
  },
};

const slice = createSlice({
  name: "tracabiliteSup",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },


    getTraceSupervisorSuccess(state, action) {
      state.isLoading = false;
      state.traceSupervisor = action.payload;
    },
    
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  getTraceAgentSuccess,
} = slice.actions;

// ----------------------------------------------------------------------

export function getTraceSupervisors(
  page = 1,
  rowsPerPage = 5,
  filterStartDate = fDateOnly(new Date(), "yyyy-M-d"),
  filterEndDate = fDateOnly(new Date(), "yyyy-M-d")
) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `/api/dashboard/tracabilites-supervisor?page=${page}&page_size=${rowsPerPage}&start_date=${filterStartDate}&end_date=${filterEndDate}`
      );
      dispatch(slice.actions.getTraceSupervisorSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}




