import { Suspense, lazy } from "react";
import { Navigate, useRoutes, useLocation } from "react-router-dom";
// layouts
import MainLayout from "../layouts/main";
import DashboardLayout from "../layouts/dashboard";
import LogoOnlyLayout from "../layouts/LogoOnlyLayout";
import Blank from "../layouts/Blank";
// guards
import GuestGuard from "../guards/GuestGuard";
import AuthGuard from "../guards/AuthGuard";
import RoleBasedGuard from "../guards/RoleBasedGuard";
// config
import { PATH_AFTER_LOGIN } from "../config";
// components
import LoadingScreen from "../components/LoadingScreen";

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense
      fallback={<LoadingScreen isDashboard={pathname.includes("/dashboard")} />}
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: "auth",
      children: [
        {
          path: "login",
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: "register",
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        { path: "reset-password", element: <ResetPassword /> },
        { path: "verify", element: <VerifyCode /> },
      ],
    },

    // Dashboard Routes
    {
      path: "dashboard",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: "home", element: <GeneralApp /> },
        {
          path: "security-and-limit",
          children: [
            {
              element: (
                <Navigate
                  to="/dashboard/security-and-limit/limit-game"
                  replace
                />
              ),
              index: true,
            },

            {
              path: "limit-game",
              element: <LimitGameList />,
            },
            {
              path: "limit-game/new",
              element: <LimitGameCreate />,
            },
            {
              path: "limit-game/:limitGameKey/edit",
              element: <LimitGameCreate />,
            },

            {
              path: "limit-game-tirage",
              element: <LimitGameTirageList />,
            },
            {
              path: "limit-game-tirage/new",
              element: <LimitGameTirageCreate />,
            },
            {
              path: "limit-game-tirage/:limitGameTirageKey/edit",
              element: <LimitGameTirageCreate />,
            },

            {
              path: "lot-gagnant",
              element: <LotGagnantList />,
            },
            {
              path: "lot-gagnant/new",
              element: <LotGagnantCreate />,
            },
            {
              path: "lot-gagnant/:lotGagnantKey/edit",
              element: <LotGagnantCreate />,
            },

            {
              path: "limit-boule",
              element: <LimitBouleList />,
            },
            {
              path: "limit-boule/new",
              element: <LimitBouleCreate />,
            },
            {
              path: "limit-boule/:limitBouleKey/edit",
              element: <LimitBouleCreate />,
            },

            {
              path: "boule-block",
              element: <BouleBlockList />,
            },
            {
              path: "boule-block/new",
              element: <BouleBlockCreate />,
            },
            {
              path: "boule-block/:bouleblockKey/edit",
              element: <BouleBlockCreate />,
            },

            {
              path: "limit-boule-tirage",
              element: <LimitBouleTirageList />,
            },
            {
              path: "limit-boule-tirage/new",
              element: <LimitBouleTirageCreate />,
            },
            {
              path: "limit-boule-tirage/:limitBouleTirageKey/edit",
              element: <LimitBouleTirageCreate />,
            },

            {
              path: "limit-ticket",
              element: <LimitTicketList />,
            },
            {
              path: "limit-ticket/new",
              element: <LimitTicketCreate />,
            },
            {
              path: "limit-ticket/:limitTicketKey/edit",
              element: <LimitTicketCreate />,
            },

            {
              path: "limit-vente",
              element: <LimitVenteList />,
            },
            {
              path: "limit-vente/new",
              element: <LimitVenteCreate />,
            },
            {
              path: "limit-vente/:limitVenteKey/edit",
              element: <LimitVenteCreate />,
            },

            {
              path: "limit-quantity-boule",
              element: <LimitQuantityBouleList />,
            },
            {
              path: "limit-quantity-boule/new",
              element: <LimitQuantityBouleCreate />,
            },
            {
              path: "limit-quantity-boule/:limitQuantityBouleKey/edit",
              element: <LimitQuantityBouleCreate />,
            },

            {
              path: "prime-tirage",
              element: <PrimeTirageList />,
            },
            {
              path: "prime-tirage/new",
              element: <PrimeTirageCreate />,
            },
            {
              path: "prime-tirage/:primeTirageKey/edit",
              element: <PrimeTirageCreate />,
            },

            {
              path: "prime-user",
              element: <PrimeUserList />,
            },
            {
              path: "prime-user/new",
              element: <PrimeUserCreate />,
            },
            {
              path: "prime-user/:primeUserKey/edit",
              element: <PrimeUserCreate />,
            },

            {
              path: "prime-succursale",
              element: <PrimeSuccursaleList />,
            },
            {
              path: "prime-succursale/new",
              element: <PrimeSuccursaleCreate />,
            },
            {
              path: "prime-succursale/:primeSuccursaleKey/edit",
              element: <PrimeSuccursaleCreate />,
            },

            {
              path: "statistiques",
              element: <Statistique />,
            },
          ],
        },
        // supervisors
        {
          path: "master-vendor",
          children: [
            {
              element: <Navigate to="/dashboard/master-vendor/list" replace />,
              index: true,
            },
            { path: "list", element: <SupervisorList /> },
            { path: "new", element: <SupervisorCreate /> },
            { path: ":supervisorKey/edit", element: <SupervisorCreate /> },
          ],
        },
        // succursales
        {
          path: "succursale",
          children: [
            {
              element: <Navigate to="/dashboard/succursale/list" replace />,
              index: true,
            },
            { path: "list", element: <SuccursaleList /> },
            { path: "new", element: <SuccursaleCreate /> },
            { path: ":succursaleKey/edit", element: <SuccursaleCreate /> },
          ],
        },
        // vendors
        {
          path: "vendor",
          children: [
            {
              element: <Navigate to="/dashboard/vendor/list" replace />,
              index: true,
            },
            { path: "list", element: <VendorList /> },
            { path: "new", element: <VendorCreate /> },
            { path: ":vendorKey/edit", element: <VendorCreate /> },
          ],
        },
        // tickets
        {
          path: "ticket",
          children: [
            {
              element: <Navigate to="/dashboard/ticket/sale" replace />,
              index: true,
            },
            { path: "sale", element: <TicketSale /> },
            { path: "sale/:id", element: <TicketDetails /> },
            { path: "win", element: <TicketWin /> },
            { path: "win/:id", element: <TicketDetails /> },
          ],
        },

        {
          path: "tracabilite",
          children: [
            {
              element: <Navigate to="/dashboard/tracabilite/list" replace />,
              index: true,
            },
            { path: "list", element: <TracabiliteList /> },
            { path: "agent", element: <TracabiliteListAgent /> },
            { path: "supervisor", element: <TracabiliteListSupervisor /> },
          ],
        },
       
        // reports
        {
          path: "report",
          children: [
            {
              element: <Navigate to="/dashboard/report/sale" replace />,
              index: true,
            },
            { path: "sale", element: <Report /> },
            { path: "user", element: <ReportUser /> },
          ],
        },
        {
          path: "user",
          children: [
            {
              element: <Navigate to="/dashboard/user/account" replace />,
              index: true,
            },
            { path: "account", element: <UserAccount /> },
          ],
        },
      ],
    },

    // Main Routes
    {
      path: "*",
      element: <LogoOnlyLayout />,
      children: [
        { path: "politique-confidentialite", element: <PolitiqueConf /> },
        { path: "termes-conditions", element: <TermesConditions /> },
        {
          path: "politique-remboursement",
          element: <PolitiqueRemboursement />,
        },
        {
          path: "politique-moderation",
          element: <PolitiqueModeration />,
        },
        {
          path: "conditions-affiliation",
          element: <ConditionsAffiliation />,
        },
        {
          path: "politique-securite",
          element: <PolitiqueSecurite />,
        },
        { path: "coming-soon", element: <ComingSoon /> },
        { path: "maintenance", element: <Maintenance /> },
        { path: "pricing", element: <Pricing /> },
        { path: "payment", element: <Payment /> },
        { path: "500", element: <Page500 /> },
        { path: "404", element: <NotFound /> },
        { path: "*", element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: "/",
      element: <MainLayout />,
      children: [
        {
          element: (
            <GuestGuard>
              <HomePage />
            </GuestGuard>
          ),
          index: true,
        },
        { path: "about-us", element: <About /> },
        { path: "contact-us", element: <Contact /> },
        { path: "faqs", element: <Faqs /> },
      ],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import("../pages/auth/Login")));
const Register = Loadable(lazy(() => import("../pages/auth/Register")));
const ResetPassword = Loadable(
  lazy(() => import("../pages/auth/ResetPassword"))
);
const VerifyCode = Loadable(lazy(() => import("../pages/auth/VerifyCode")));

// DASHBOARD

// GENERAL
const GeneralApp = Loadable(
  lazy(() => import("../pages/dashboard/GeneralApp"))
);

// LIMIT GAME
const LimitGameList = Loadable(
  lazy(() => import("../pages/dashboard/LimitGameList"))
);

const LimitGameCreate = Loadable(
  lazy(() => import("../pages/dashboard/LimitGameCreate"))
);

// LIMIT GAME TIRAGE
const LimitGameTirageList = Loadable(
  lazy(() => import("../pages/dashboard/LimitGameTirageList"))
);

const LimitGameTirageCreate = Loadable(
  lazy(() => import("../pages/dashboard/LimitGameTirageCreate"))
);
// LOT GAGNANT
const LotGagnantList = Loadable(
  lazy(() => import("../pages/dashboard/LotGagnantList"))
);

// TRACABILITE 
const TracabiliteList = Loadable(
  lazy(() => import("../pages/dashboard/TracabiliteList"))
);

const TracabiliteListAgent = Loadable(
  lazy(() => import("../pages/dashboard/TracabiliteListAgent."))
);


const TracabiliteListSupervisor = Loadable(
  lazy(() => import("../pages/dashboard/TracabiliteListSupervisor"))
);

const LotGagnantCreate = Loadable(
  lazy(() => import("../pages/dashboard/LotGagnantCreate"))
);

// LIMIT BOULE
const LimitBouleList = Loadable(
  lazy(() => import("../pages/dashboard/LimitBouleList"))
);

const LimitBouleCreate = Loadable(
  lazy(() => import("../pages/dashboard/LimitBouleCreate"))
);

// BOULE BLOCK
const BouleBlockList = Loadable(
  lazy(() => import("../pages/dashboard/BouleBlockList"))
);

const BouleBlockCreate = Loadable(
  lazy(() => import("../pages/dashboard/BouleBlockCreate"))
);

// LIMIT BOULE TIRAGE
const LimitBouleTirageList = Loadable(
  lazy(() => import("../pages/dashboard/LimitBouleTirageList"))
);

const LimitBouleTirageCreate = Loadable(
  lazy(() => import("../pages/dashboard/LimitBouleTirageCreate"))
);

// LIMIT TICKET
const LimitTicketList = Loadable(
  lazy(() => import("../pages/dashboard/LimitTicketList"))
);

const LimitTicketCreate = Loadable(
  lazy(() => import("../pages/dashboard/LimitTicketCreate"))
);

// LIMIT VENTE
const LimitVenteList = Loadable(
  lazy(() => import("../pages/dashboard/LimitVenteList"))
);

const LimitVenteCreate = Loadable(
  lazy(() => import("../pages/dashboard/LimitVenteCreate"))
);

// LIMIT QUANTITY BOULE
const LimitQuantityBouleList = Loadable(
  lazy(() => import("../pages/dashboard/LimitQuantityBouleList"))
);

const LimitQuantityBouleCreate = Loadable(
  lazy(() => import("../pages/dashboard/LimitQuantityBouleCreate"))
);

// PRIME TIRAGE
const PrimeTirageList = Loadable(
  lazy(() => import("../pages/dashboard/PrimeTirageList"))
);

const PrimeTirageCreate = Loadable(
  lazy(() => import("../pages/dashboard/PrimeTirageCreate"))
);

// PRIME USER
const PrimeUserList = Loadable(
  lazy(() => import("../pages/dashboard/PrimeUserList"))
);

const PrimeUserCreate = Loadable(
  lazy(() => import("../pages/dashboard/PrimeUserCreate"))
);

// PRIME SUCCURSALE
const PrimeSuccursaleList = Loadable(
  lazy(() => import("../pages/dashboard/PrimeSuccursaleList"))
);

const PrimeSuccursaleCreate = Loadable(
  lazy(() => import("../pages/dashboard/PrimeSuccursaleCreate"))
);

// TICKET SALE
const TicketSale = Loadable(
  lazy(() => import("../pages/dashboard/TicketSale"))
);

// TICKET WIN
const TicketWin = Loadable(lazy(() => import("../pages/dashboard/TicketWin")));

const TicketDetails = Loadable(
  lazy(() => import("../pages/dashboard/TicketDetails"))
);

// REPORT
const Report = Loadable(
  lazy(() => import("../pages/dashboard/Report/ReportInit"))
);

// REPORT AGENT
const ReportUser = Loadable(
  lazy(() => import("../pages/dashboard/ReportUsers/ReportInit"))
);

// STATISTIQUE
const Statistique = Loadable(
  lazy(() => import("../pages/dashboard/Statistique"))
);

// SUPERVISOR
const SupervisorList = Loadable(
  lazy(() => import("../pages/dashboard/SupervisorList"))
);

const SupervisorCreate = Loadable(
  lazy(() => import("../pages/dashboard/SupervisorCreate"))
);

// SUCCURSALE
const SuccursaleList = Loadable(
  lazy(() => import("../pages/dashboard/SuccursaleList"))
);

const SuccursaleCreate = Loadable(
  lazy(() => import("../pages/dashboard/SuccursaleCreate"))
);
// VENDOR
const VendorList = Loadable(
  lazy(() => import("../pages/dashboard/VendorList"))
);

const VendorCreate = Loadable(
  lazy(() => import("../pages/dashboard/VendorCreate"))
);

// USER
const UserAccount = Loadable(
  lazy(() => import("../pages/dashboard/UserAccount"))
);
// MAIN
const HomePage = Loadable(lazy(() => import("../pages/Home")));
const About = Loadable(lazy(() => import("../pages/About")));
const Contact = Loadable(lazy(() => import("../pages/Contact")));
const PolitiqueConf = Loadable(lazy(() => import("../pages/PolitiqueConf")));
const TermesConditions = Loadable(
  lazy(() => import("../pages/TermesConditions"))
);
const PolitiqueRemboursement = Loadable(
  lazy(() => import("../pages/PolitiqueRemboursement"))
);
const PolitiqueModeration = Loadable(
  lazy(() => import("../pages/PolitiqueModeration"))
);
const ConditionsAffiliation = Loadable(
  lazy(() => import("../pages/ConditionsAffiliation"))
);
const PolitiqueSecurite = Loadable(
  lazy(() => import("../pages/PolitiqueSecurite"))
);
const Faqs = Loadable(lazy(() => import("../pages/Faqs")));
const ComingSoon = Loadable(lazy(() => import("../pages/ComingSoon")));
const Maintenance = Loadable(lazy(() => import("../pages/Maintenance")));
const Pricing = Loadable(lazy(() => import("../pages/Pricing")));
const Payment = Loadable(lazy(() => import("../pages/Payment")));
const Page500 = Loadable(lazy(() => import("../pages/Page500")));
const NotFound = Loadable(lazy(() => import("../pages/Page404")));
